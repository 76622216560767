import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Autoplay, Pagination } from "swiper";

import "./horoscopepage.css";

import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
import aries from "../img/horoscope/aries.png";
import taurus from "../img/horoscope/taurus.png";
import gemini from "../img/horoscope/gemini.png";
import cancer from "../img/horoscope/cancer.png";
import leo from "../img/horoscope/leo.png";
import virgo from "../img/horoscope/virgo.png";
import libra from "../img/horoscope/libra.png";
import scorpio from "../img/horoscope/Scorpio.png";
import Sagittarius from "../img/horoscope/Saggitarius.png";
import capricorn from "../img/horoscope/Capricorn.png";
import aquarius from "../img/horoscope/Aquarius.png";
import pisces from "../img/horoscope/Pisces.png";
import s1 from "../img/service/1.png";
import s2 from "../img/service/2.png";
import s3 from "../img/service/3.png";
import s4 from "../img/service/4.png";
import s5 from "../img/service/pa.png";
import s6 from "../img/service/nu.png";
import s7 from "../img/service/re.png";

export default function horoscopepage() {
  return (
    <>
      <Helmet>
        <title>Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar</title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          name="keywords"
          content="Horoscope Prediction, Aries horoscope, Taurus horoscope, taurus weekly horoscope, Gemini horoscope, Cancer horoscope, weekly Leo horoscope, Virgo horoscope, virgo weekly horoscope, weekly Libra horoscope"
        />

        <link
          rel="canonical"
          href="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          property="og:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          property="og:url"
          content="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta
          property="og:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
        <meta
          name="twitter:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          name="twitter:description"
          content="Predict your weekly Horoscope By Dr. Surabhi Bhatnagar - Best Astrologer in India, Check your Moon and Ascendant Signs. Aries Horoscope Prediction."
        />
        <meta
          name="twitter:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
      </Helmet>
      <section className="inner-section horoscope-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="horoscope-section pt-4 py-lg-5">
          <div className="container">
            <div className="row">
              <div className="section-head text-center mb-0 col-12">
                <h1 className="pb-2">
                  <span>Weekly</span> Horoscope Prediction
                </h1>
                <h5>11th Nov - 17th Nov</h5>
                <p>Check your Moon and Ascendant Signs</p>
              </div>
              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aries} alt="Astrology Services"></img>
                  </div>
                  <h3>Aries</h3>
                  <p className=" ">
                  This week, you will be successful in getting rid of all hindrances coming to you in various fields and there will be positivity all around you. Due to Rahu being in the twelfth house, you might find it difficult to concentrate and focus at work. It is advised that you should be extremely careful in choosing words at your workplace, especially in the meetings while placing your thoughts. You should prepare well before any presentation as you will be the centre person and will be asked much. Spend wisely and aim to save more for the future. Be committed to your goals to achieve success in all your endeavours.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={taurus} alt="Astrology Services"></img>
                  </div>
                  <h3>Taurus</h3>
                  <p className=" ">
                  This week would be better than usual for you in monetary benefits. You also stand a chance of getting sudden benefit from some loved one / in laws/ ancestral property. You will be getting new opportunities to move forward in your area of expertise, hence make proper use of them. Due to Saturn being placed in the house of profession, You might be burdened with excess work at your workplace which can give you mental stress. Saturn wants hardwork and it will reward you accordingly. Make sure to make a balance between your personal and professional lives. Keep a check on your calorie content.                    </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={gemini} alt="Astrology Services"></img>
                  </div>
                  <h3>Gemini</h3>
                  <p className=" ">
                  This week, many favourable planetary constellations will help you in many areas of your life, especially health. Take proper advantage of these combinations. It is advised that you keep a check on your pocket and save more. There are chances that you will have to make some major decisions this week but take proper advice and your elders in loop. Take family into consideration and then finalise. Fate at the professional front will be highly favourable and good profits from several sources will be earned. Help from the government in business will be given and success will be yours. Maintain a diet routine .                   </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Blue
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 4
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={cancer} alt="Astrology Services"></img>
                  </div>
                  <h3>Cancer</h3>
                  <p className=" ">
                  This week is very important as it will regenerate positivity and will help bring new insights. You have been burdened with negative responses and hurdles for a long time and now it's time to come out of the darkness. It is advised that you should try to save money and refrain from extravagant expenses. You will spend happy moments with family and an atmosphere of celebration will be there. It is also recommended that you should either refrain from doing any promises or fulfil them at your best. Avoid laziness and remain active in participating in physical activities. Saturn in the eighth house will help bring in new opportunities that were hidden and will bring progress .                    </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Orange
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 1
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={leo} alt="Astrology Services"></img>
                  </div>
                  <h3>Leo</h3>
                  <p className=" ">
                  This week, you should understand that taking rest will prove to be highly favourable. Rahu being placed in the eighth house, you will be spending on many things related to personal and professional areas. You might feel stuck in taking decisions regarding some matters and feel discomfort. You try to help others more than your capability hence promise what you can deliver. You will be able to improve your relationships with your superiors. This will not only benefit you in enhancing your image but it will also help in increased chances of promotion and increment.                          </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> White
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 2
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={virgo} alt="Astrology Services"></img>
                  </div>
                  <h3>Virgo</h3>
                  <p className=" ">
                  This week, you will be able to keep yourself away from all mental stress . You are expected to make good profits and as a result you will be able to accumulate a good sum of money in savings. You can even invest this money in real estate or land which will be profitable for you. Due to Saturn being placed in the sixth house, it is advised that you should act smart and use your intelligence and influence from the very beginning to resolve the matters at the workplace. Give the demonstration of your capabilities and achieve your goals. Health of the family will be good and there will be an atmosphere of happiness in the family.                     </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={libra} alt="Astrology Services"></img>
                  </div>
                  <h3>Libra</h3>
                  <p className=" ">
                  This week, your social connection and ability to network will help you a lot in expanding your professional domain. There are auspicious planetary combinations which will help you earn good profits this week. There will be many instances where you will find your family and friends standing like a pillar with you and rendering their support unconditionally. You will be benefited from your seniors and superiors and will be rewarded for your work. This stands true for people serving in government institutions or are working with government where they will get benefits from government.Be dedicated towards health                     </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={scorpio} alt="Astrology Services"></img>
                  </div>
                  <h3>Scorpio</h3>
                  <p className=" ">
                  This week, you should focus on your tasks and spend your energy on prioritised work rather than on everything. You can aim to invest in things like land, real estate or good projects. These investments will bring you good profits hence do not let these opportunities slip from your hand. Professionally this period is highly favourable to gain fame and respect in your society and family and great success, especially people who have business. Family relations and ties will be strengthened and closeness will increase. Invest your time in understanding the needs of the family.Be careful in using words while speaking with people at the workplace.                      </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Orange
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 1
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={Sagittarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Sagittarius</h3>
                  <p className=" ">
                  This week, Saturn being placed in the third house , you will observe that this period will bring better results for you than usual. You will strengthen your position in society and there will be an increase in wealth too. You will also find many wonderful opportunities coming your way at this time. Family holds a special position in your heart and this week you will be involved in many decisions related to them which will make you quite emotional. You will also see an increase in luxuries and will get a little careless about your career which is not advisable. Rahu in fourth house can make you angry too                            </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Red
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 9
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={capricorn} alt="Astrology Services"></img>
                  </div>
                  <h3>Capricorn</h3>
                  <p className=" ">
                  This week, you will act as an inspiration for others and they will not only emulate you but also motivate you. Saturn in the second house urges you to work hard to get better results. The more efforts done , the more it will help in earning more money and saving too. In fact your financial condition will start improving as the week passes. It is advised that you should be respectful towards your seniors and mentors at the workplace and elders at home. Be careful of the words used with them. Your efforts done in the past will help you get more and good results with less inputs at the workplace .                          </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aquarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Aquarius</h3>
                  <p className=" ">
                  This week, you will have to make a good plan and adopt the right strategy for increased income, more profits and saving. This period will be good for professionals. Presence of auspicious combinations and favourable planets will help you possess great observational and observational skills which will help you immensely in your career. You are advised to keep patience and focus on your work to achieve goals to avoid many problems which might arise and create hurdles. There are chances that you will be spending money on renovation and decoration of your home. Family will respect you.                                </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={pisces} alt="Astrology Services"></img>
                  </div>
                  <h3>Pisces</h3>
                  <p className=" ">
                  This week, due to Saturn in your twelfth house, there is a possibility that you will land up with a big profit but it is advised that you should not spend your savings and profits in unnecessary things thinking that you can spare much. Ketu in the seventh house will present the opportunities where you will get the work of your choice and need but keep calm and do everything with diligence without being in a hurry. You will be able to get desired promotions and increments too at the workplace. The key is to keep focussed and concentrate. There will be ups and downs in family but be strong and do your work.                             </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <Col xs={12} className="section-head t-head mb-0 my-4">
                {/* <h4><span>What Do Our Clients Say</span> About Us?</h4> */}
                <h4>
                  <span>Our</span> Services
                </h4>
              </Col>

              <div className="col-12">
                <Swiper
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper testimonial-swiper services-swiper pt-5">
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s1} alt="Astrology Services"></img>
                        </div>
                        <h3>Astrology</h3>
                        <p>
                          Astrology, also known as Jyotish, is an ancient and
                          divine knowledge that can see into our past...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/astrology-service"
                            className="button button-2">
                            Read More{" "}
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s2} alt="Vastu Shastra services"></img>
                        </div>
                        <h3>Vastu Shastra</h3>
                        <p>
                          Vastu Shastra is a divine science that we have used
                          since ancient times to plan and build our homes...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/vastu-shastra-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img
                            src={s3}
                            alt="Handwriting and Signature Analysis (Astrology)"></img>
                        </div>
                        <h3>Handwriting & Signature Analysis</h3>
                        <p>"We can judge a person by looking at his...</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/handwriting-signature-analysis"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s4} alt="Tarot Card Reading"></img>
                        </div>
                        <h3>Tarot</h3>
                        <p>
                          Tarot card reading is an ancient technique that was
                          popular in Europe centuries ago and has now spread all
                          ...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a href="/tarot-service" className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s5} alt="Palmistry Services" />
                        </div>
                        <h3>Palmistry</h3>
                        <p>
                          Palmistry is an age old technique in which an
                          astrologer reads and analyzes the palm of the
                          native....
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/palmistry-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s6} alt="Numerology services" />
                        </div>
                        <h3>Numerology</h3>
                        <p>
                          Numerology is the wing of astrology where we consider
                          numbers and predict the past, present and future.
                          Numerology helps people...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/numerology-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s7} alt="Reiki Healing Services" />
                        </div>
                        <h3>Reiki Healing</h3>
                        <p>
                          It is the japanese technique which makes use of hands
                          and invokes energy and blessings from the universe. It
                          is an age-old...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/reikihealing-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
