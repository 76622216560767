import Qrbg from "../img/1.png";
import Clientimg from "../img/author.jpeg";
import { IoCall, IoMail } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import "./Qr.css";
import Logo from "../img/blessings-astrology-logo.png";

function Qrform() {
  return (
    <div className="container-fluid p-0 m-0 d-flex justify-content-center">
      <div className="row p-0 m-0 fix-size mb-4 position-relative w-100">
        <div className="col-12 qrbg-style position-relative d-flex justify-content-center p-0 mx-0 mt-0 mb-3">
          <img src={Qrbg} className="w-100" />
          <div className="Clientimg position-absolute">
            <div className="client-img">
              <img className="client-img" src={Logo} />
            </div>

            <div className="w-100 my-4 px-3">
              <div className="bless-logo text-start">
                <img src={Clientimg} className="w-100" />
              </div>
              <div>
                <h1 className="mt-4 text-start fw-bold mb-0 pb-0 fs-5">
                  Dr. Surabhi Bhatnagar
                </h1>
                <h2 className="mt-0 mb-0 py-2 text-start fs-6">
                  Spiritual Consultant and Healer
                </h2>
              </div>
            </div>

            {/* <h1 className="mt-4 text-center fw-bold mb-0 pb-0 fs-5">
              Dr. Surabhi Bhatnagar
            </h1> */}
            {/* <h2 className="mt-3 mb-0 py-2 text-center fs-6">
              Spiritual Consultant and Healer
            </h2> */}
          </div>
        </div>

        <div className="col-12 mb-0 pb-2 px-3 contact-info-box   contact-details">
          <div className="mail-icon">
            <IoCall className="p-2" />
          </div>
          <div className="qr-font d-flex align-items-center">
            <a href="tel:+919821155251 ">+91- 98211 55251</a>
          </div>
        </div>

        <div className="col-12 mb-0 pb-2 px-3 contact-info-box   mt-2">
          <div className="mail-icon">
            <IoMail className="p-2" />
          </div>
          <div className="qr-font d-flex align-items-center">
            <a
              href="mailto:surabhibhatnagar@blessingsastrology.com"
              className="qr-mail"
            >
              {" "}
              surabhibhatnagar@blessingsastrology.com{" "}
            </a>
          </div>
        </div>

        <div className="col-12 mb-0 pb-2 px-3 contact-info-box   mt-2">
          <div className="mail-icon">
            <TbWorld className="p-2" />
          </div>
          <div className="qr-font d-flex align-items-center">
            <a href="https://www.blessingsastrology.com/" target="_blank">
              www.blessingsastrology.com
            </a>
          </div>
        </div>

        <div className="col-12 mb-0 pb-2 px-3 contact-info-box mt-2">
          <div className="mail-icon">
            <MdLocationOn className="p-2" />
          </div>
          <div className="d-flex align-items-center">
            <span className="qr-font">
              {/* <strong> Blessings Astrology</strong> */}
              Tower - 9 - 002,
              <br />
              Emaar Palm Gardens,
              <br />
              Sec - 83, Gurgaon
              <br /> Haryana - 122004
            </span>
          </div>
        </div>
        <div className="col-12 mb-0 pb-0 mb-md-0 mt-3 text-center d-flex justify-content-center flex-column">
          <span className="fw-bold qr-font">Join Us</span>
          <ul className="d-flex justify-content-center mt-3 ms-0 ps-0 social-link-qr">
            <li className="ms-0 ps-0">
              <a
                href="https://www.facebook.com/blessings.astrologysolutions?_rdr"
                target="_blank"
              >
                <FaFacebookF className="qricon p-2" />
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag"
                target="_blank"
              >
                <AiFillInstagram className="qricon p-2" />
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ"
                target="_blank"
              >
                <FaYoutube className="qricon p-2" />
              </a>
            </li>

            <li>
              <a href="https://wa.me/+919821155251" target="_blank">
                <IoLogoWhatsapp className="qricon p-2" />
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/"
                target="_blank"
              >
                <FaLinkedin className="qricon p-2" />
              </a>
            </li>
          </ul>
        </div>

        <div className="col-12 mt-1">
          <div className="row mb-2 justify-content-center">
            <div className="col-12 text-center qr-btn py-2 px-5">
              <div className="btn-box text-white">
                <a
                  href="/"
                  className="button button-2 py-2 text-white fw-normal "
                >
                  Visit Our Website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Qrform;
